.hidden{
	visibility: hidden;
}
.row{
    overflow: hidden; 
}
.full-width{
	width:100%;
}

.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
}
#mainContainerDiv{
	transition: all 0.5s  ease-in-out; 
}
.body{
	font-family: "Prompt"
}

.no-padding {
    padding: 0 0 0 0;
}
.main-container{
	padding-top: 5px;
	padding-left: 5px!important
}
.dark {
	background-color: #1a4270!important;
	color: #fff;
}
.medium {
	background-color: #7ea3cc!important;
	color: #262626;
}
.light{
	background-color: #fff;
	color: #1a4270;	
}
.right{
	float:right;
	text-align: right;
}
.left{
	float:left;
	text-align: left;
}
.bg-warning{
	background-color: #ee7e4c!important ;
}
.sublabel{
	display: block;
}
.center-text{
	text-align:center;
}
.middle{
	vertical-align: middle;
}
.center{
	float:none!important;
    text-align: center!important
}
.text-ellipsis{
	text-overflow:ellipsis;
}
/*$main-color-light: #7ea3cc;
$main-color-dark: #1a4270;
$main-color-black: #262626;
$main-color-white: #fff;
$main-color-grey-dark: #808080;
$main-color-grey: #d3d3d3;
$main-color-grey-light: #eee;
$main-color-punchy: #ce4c14;
$main-color-punchy-light: #ee7e4c ;*/
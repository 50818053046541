/*#sidebarAuxDiv{
    position:absolute;
    top:0;
    right:-12px;
    width:12px;
    bottom:0;
}*/

#mainSidebarDiv{
    padding-left: 0;
    padding-right: 0;
    background-color:#1a4270 ;
    /*margin-bottom: -99999px;
    padding-bottom: 99999px;*/
}

.fliph h4, .fliph hr {
    visibility: hidden;
}

hr { 
    display: block;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-left: auto;
    margin-right: auto;
    border-style: inset;
    border-width: 2px;
    color:#fff;
} 
.sidebar{
    width: 100%;
    /*margin-bottom: -99999px;
    padding-bottom: 99999px;*/
    position:sticky;  
    left: 0;
    transition: width 0s;
    padding-left: 0;
    padding-right: 0;
    z-index: 100;   
}
.sidebar ul{
    list-style:none;
    margin:0px;
    padding:0px;
}
.sidebar li a,.sidebar li a.collapsed.active{
    display:block;
    padding: 8px 12px;
    color:#fff;
    border-left: 0px solid #7ea3cc ;
    text-decoration:none
}
.sidebar li a.active{
    background-color:#1a4270;
    border-left:5px solid #7ea3cc;
    /*transition: all 0.25s  ease-in-out*/
}
.sidebar li a:hover{
    background-color:#7ea3cc !important;
}
.sidebar li a i{
    padding-right:5px;
}
.sidebar ul li .sub-menu li a{
    position:relative
}
.sidebar ul li .sub-menu li a:before{
    font-family: FontAwesome;
    content: "\f105";
    display: inline-block;
    padding-left: 0px;
    padding-right: 10px;
    vertical-align: middle;
}
.sidebar ul li .sub-menu li a:hover:after {
    content: "";
    position: absolute;
    left: -5px;
    top: 0;
    width: 5px;
    background-color: #7ea3cc;
    height: 100%;
}
.sidebar ul li .sub-menu li a:hover{
    background-color:#f00;
    padding-left:20px;
    /*transition: all 0.25s  ease-in-out*/
}
.sub-menu{
    border-left:5px solid #dedede ;
}
.sidebar li a .nav-label,.sidebar li a .nav-label+span{
    /*transition: all 0.25s  ease-in-out*/
}
.sidebar.fliph li a .nav-label,.sidebar.fliph li a .nav-label+span{
    display:none;
    /*transition: all 0.25s  ease-in-out*/
}
.sidebar.fliph {
    width: 42px;
    /*transition: all 0.25s  ease-in-out;*/
}
.sidebar.fliph li{
    position:relative
}
.sidebar.fliph .sub-menu {
    position: absolute;
    left: 39px;
    top: 0;
    background-color: #1a4270;
    width: 150px;
    z-index: 101;
}
.btn-primary{
	background-image: linear-gradient(to bottom,#7ea3cc 0,#1a4270 100%);
	background-color: #1a4270;
}

.btn-primary:hover {
	background-image: none!important;
	background-color: #1a4270!important;
	background-position: unset!important;
}


/*$main-color-light: #7ea3cc;
$main-color-dark: #1a4270;
$main-color-black: #262626;
$main-color-white: #fff;
$main-color-grey-dark: #808080;
$main-color-grey: #d3d3d3;
$main-color-grey-light: #eee;
$main-color-punchy: #ce4c14;
$main-color-punchy-light: #ee7e4c ;*/
.footer{
    background-color: #7ea3cc;
    margin:0;
    margin-top: 5px;
    padding-top: .25rem;
    padding-bottom: .25rem;
    left: 0;
    bottom: 0;
    right: 0;
}
.footer .container-fluid .row{
	padding: 0;
}
.footer .container-fluid .row p{
	margin: 0;
}
th[scope=row]{
	text-align: right;
	font-weight: initial;
	padding-right: 1em;
}

th[scope=col]{
	text-align: left;
	font-weight: initial;
	font-style: italic;
}

td{
	text-align: left;
}
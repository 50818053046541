#userDiv{
    padding: 0 0 .5rem 0;
    float:right;
    text-align: right;
    display: block;
}
.user-panel {
    width: 100%;
}
.user-panel .dropdown {
    display: inline-table;
    margin-bottom: 0;
    padding-bottom: 0;
}
#userDiv > ul {
    display: block!important;   
    margin: 0;
    padding:0;
}
#userDiv > li,#userDiv >li> a {
    display: inline-block;   
    padding: .25rem 0 0 .75rem;
    margin: 0 0 0  0;
}
.header .user-image {
    float: left;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 10px;
    margin-top: -2px;
}